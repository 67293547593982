import { User } from '@models/user.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';

export const userFeatureKey = 'user';

export interface State extends EntityState<User> {
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

export function selectUserId(u: User) {
  return u.uid;
}

export function sortByName(u1: User, u2: User) {
  return u1.name.localeCompare(u2.name);
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: selectUserId,
  sortComparer: sortByName,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsers, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(UserActions.loadUsersSuccess, (state, { users }) => {
    return adapter.upsertMany(users, {
      ...state,
      loaded: true,
      loading: true,
    });
  }),
  on(UserActions.loadSingleUser, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(UserActions.addSingleUser, (state, { user }) => {
    console.warn(user);
    return adapter.upsertOne(user, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(UserActions.addUsers, (state, { users }) => {
    return adapter.addMany(users, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(UserActions.loadUsersFailure, (state) => state),
  on(UserActions.updateUserHorsesLoadStatus, (state, { horsesLoadStatusUpdatedUser }) => {
    return adapter.upsertOne(horsesLoadStatusUpdatedUser, {
      ...state,
      loading: false,
      loaded: true,
    });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsersReducer = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;
